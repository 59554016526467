import React from "react"

var __html = require('../html/termsHtml.js');
var template = { __html: __html };

export default () => (
  <div className="screen-share">
    <span dangerouslySetInnerHTML={template} />
  </div>
)
